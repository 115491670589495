
var gesamt = 0;
var lastscroll = 0;
var scrolldirection = "";
var klick = 0;
var jqui = 0;


$.getScript('/files/feelgood/layout/js/jquery-ui.js', function() {
  log('jQuery UI Loaded');
  jqui = 1;

  if($('#datum').length > 0) {
    $('#datum').datepicker({

      monthNames: ['Januar','Februar','März','April','Mai','Juni',
        'Juli','August','September','Oktober','November','Dezember'],
        monthNamesShort: ['Jan','Feb','Mär','Apr','Mai','Jun',
        'Jul','Aug','Sep','Okt','Nov','Dez'],
        dayNames: ['Sonntag','Montag','Dienstag','Mittwoch','Donnerstag','Freitag','Samstag'],
        dayNamesShort: ['So','Mo','Di','Mi','Do','Fr','Sa'],
        dayNamesMin: ['So','Mo','Di','Mi','Do','Fr','Sa'],
            showWeek: false,
            firstDay: 1,
            dateFormat: 'dd.mm.yy',
            minDate:'0',
            maxDate: '+7',
            regional: 'de'

    });
  }
  
  
});




(function ($) {
    /**
    * Tests if a node is positioned within the current viewport.
    * It does not test any other type of "visibility", like css display,
    * opacity, presence in the dom, etc - it only considers position.
    * 
    * By default, it tests if at least 1 pixel is showing, regardless of
    * orientation - however an optional argument is accepted, a callback
    * that is passed the number of pixels distant between each edge of the
     * node and the corresponding viewport.  If the callback argument is provided
     * the return value (true of false) of that callback is used instead.
    */
    $.fn.isOnScreen = function (test) {

        var height = this.outerHeight();
        var width = this.outerWidth();

        if (!width || !height) {
            return false;
        }

        var win = jQuery(window);

        var viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
        };
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();

        var bounds = this.offset();
        bounds.right = bounds.left + width;
        bounds.bottom = bounds.top + height;

        var deltas = {
            top: viewport.bottom - bounds.top,
            left: viewport.right - bounds.left,
            bottom: bounds.bottom - viewport.top,
            right: bounds.right - viewport.left
        };

        if (typeof test == 'function') {
            return test.call(this, deltas);
        }
        /*
            return (!( viewport.right < bounds.left 
                && viewport.left > bounds.right 
                && viewport.bottom < bounds.top 
                && viewport.top > bounds.bottom
            ));
        */

        return deltas.top > 0
            && deltas.left > 0
            && deltas.right > 0
            && deltas.bottom > 0;
    };

})(jQuery);




$(document).ready(function(){

    /*
    if (window.location.hash) {
       
       scrollContent();


       
    }*/

    /*
    
    if(window.location.hash) {
        h = window.location.hash;
        id = h.substring(1,h.length);
        $('html, body').animate({
            'scrollTop': $('#'+id).offset().top-170
        }, 900);
        return false;
    }*/



    if( $('body.startseite').length > 0) {

        intro();

    }

    $('#main .ce_text, #main h2, .kurs_switch, #kurscontainer, .ce_image, .mobile_container').each(function () {


        if ($(this).isOnScreen()) {
            
            $(this).removeClass('ani');
            $(this).removeClass('ani2');
            $(this).removeClass('ani3');

        }

    });
   

    var w = $(window).width();
    
    if( w <= 1280) {
       for(i = 2; i<=7; i++) {
           $('#wt_'+i).addClass('cloak');
       } 
    }

   
    
});


$(window).scroll(function () {



    $('#main .ce_text, #main h2, .kurs_switch, #kurscontainer, .ce_image, .mobile_container').each(function () {


        if ($(this).isOnScreen()) {
           
            $(this).removeClass('ani');
            $(this).removeClass('ani2');
            $(this).removeClass('ani3');

        }

    })


    
    globalpos = $(window).scrollTop();

    //log("globalpos: "+ globalpos);

    if(globalpos > 200) {
        $('#header_prime').addClass('hidemenue');
    } else {
        $('#header_prime').removeClass('hidemenue');
    }


    if(globalpos > lastscroll) {
     
     
      //log("scrolldown");
      lastscroll = globalpos;
      scrolldirection = "down";
      if(globalpos > 200) {
        $('#header_prime').addClass('hidemenue');
      }
    } else {
     lastscroll = globalpos;
     //log("scrollup");
     
     if(klick == 0) {
         $('#header_prime').removeClass('hidemenue');
     }
     klick = 0;
     scrolldirection = "up";
    }



});




function intro() {

    log("Intro an");
    setTimeout(function () {
        $('.e1 #intro1').removeClass('ani1');
        

    }, 1500);   

    setTimeout(function () {
        $('.e1 #intro2').removeClass('ani2');

    }, 1800);  
    
    setTimeout(function () {
        $('.e1 #intro3').removeClass('ani3');

    }, 3000); 
   
    setTimeout(function () {
        $('.e1 #intro4').removeClass('ani4');

    }, 5000); 
   
}


function change_wochentag(i) {

    $('#kwa div').removeClass('active');
    $('#kwa #a_'+i).addClass('active');

    /* Kursauflistung */
    $('#kurscontainer .kcc').addClass('cloak');
    $('#kurscontainer #wt_'+i).removeClass('cloak');


    //Knöbbe

    $('#kws a').removeClass('inactive');
    $('#kws #b_'+i).addClass('inactive');


}

$(function(){

    $('#mobile_kursswitch').change(function(){

        var v = $(this).val();
        

        $('#kurscontainer .kcc').addClass('cloak');
        $('#kurse_gesamt .kurs_row').addClass('cloak');
        $('#wt_'+ v).removeClass('cloak');
    });


    $('.kurs_row').hover(function(){
        if(gesamt == 0) {
            id = $(this).attr('id');

            $('#kurse_gesamt div').removeClass('active');
           
            $('#'+id).addClass('active');
            $('.aktday').addClass('active');
        } 
       

    });
   
    
    $('.mod_navigation .level_2 a').click(function(e){
        klick = 1;
        $('#header_prime').addClass('hidemenue');

    });

    $('#datum').click(function(){

        if(jqui == 1) {
          $('#datum').datepicker();
        }

    });


    $('#von').click(function(){
        $(this).val("");
        baueZeit('#von');
    });
    

});


function open_mobile_menue(id) {
    $('.submenuecontainer').css('display','none');
    $('#sub'+id).css('display','block');
}

function zeigekomplettenKursplan() {
    $('.kurs_row').addClass('active');
    gesamt = 1;
  
    $('html, body').animate({
        'scrollTop': $('#kurse_gesamt').offset().top-180
       }, 900);
   
}



function scrollContent() {

    //log("scrolling an");

    h = window.location.hash;
    //log(h);
    id = h.substring(1,h.length);
    //log(id);
    $('html, body').animate({
        'scrollTop': $('#'+id).offset().top-160
    }, 1200);
}




function baueZeit(target) {
	

  aZeit = [];
  q = 0;
  for(i = 8; i <= 21; i++) {

    for(y = 0; y<=45; y+=15) {

      stunde = i;

      if(i <= 9) {
        stunde = "0"+i;
      }

      minute = y;

      if(y < 9) {
        minute = "0"+y;
      }
      
      aZeit[q] = stunde+":"+minute;
      q++;
    }

  }
  
  
  html = "";

  for(i = 0; aZeit.length-1; i++) {
    html += '<a href="javascript:void(0)">'+aZeit[i]+'</a>';
  }

  $('#timechooser').html(html);
}


function log(l) {

    console.log(l);

}



$.getScript('/files/feelgood/layout/js/lightbox.js', function() {

  log('lightbox loaded');

});
